import React, { useMemo, useState } from 'react';
import './LabelRadiobutton.sass';
import PropTypes from 'prop-types';
import { CustomRadiobutton } from 'components/CustomRadiobutton/CustomRadiobutton';
import { CustomTooltip } from 'components/CustomTooltip/CustomTooltip';
import { IconButton } from '@material-ui/core';
import { InfoIcon } from 'components/Icons/InfoIcon';

const LabelRadiobutton = ({
  label,
  id,
  value,
  onChange,
  disabled,
  icon,
  isEdited,
  name,
  tooltipText,
  error,
  checked,
}) => {
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  const getClassnameForError = useMemo(() => {
    if (error) return 'error';
    return '';
  }, [error]);

  return (
    <div className="label-radiobutton">
      <CustomRadiobutton
        id={id}
        value={value}
        onChange={onChange}
        disabled={disabled}
        name={name}
        isEdited={isEdited}
        tooltipText={tooltipText}
        error={error}
        checked={checked}
      />
      {icon && <img src={icon} alt="label-radiobutton-icon" />}
      {label && (
        <label id={id} className={getClassnameForError}>
          {label}
        </label>
      )}
      {tooltipText && (
        <CustomTooltip
          title={tooltipText}
          placement="top"
          open={isTooltipOpen}
          onClose={() => setTooltipOpen(false)}
          onOpen={() => {
            if (tooltipText) setTooltipOpen(true);
          }}>
          <IconButton onClick={() => setTooltipOpen(!isTooltipOpen)}>
            <InfoIcon />
          </IconButton>
        </CustomTooltip>
      )}
    </div>
  );
};

LabelRadiobutton.propTypes = {
  value: PropTypes.string.isRequired || PropTypes.number.isRequired,

  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  isEdited: PropTypes.bool,
  name: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  error: PropTypes.bool,
};

export { LabelRadiobutton };
